(function ($) {
  gnavOfferV1 = {
    formatter: $('.gnav-offer-v1')
  };

  Drupal.behaviors.gnavOfferV1 = {
    get: function (key) {
      return gnavOfferV1[key];
    },

    set: function (key, val) {
      gnavOfferV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');
      var current_path = location.pathname;

      formatter.each(function () {
        var thisFormatter = $(this);
        var link = thisFormatter.attr('data-image-link');

        if (link !== null && link.length !== 0) {
          thisFormatter.wrapInner('<a href="' + link + '" class="wrapper-link"></a>');
        }

        // check lbo cookie
        var offer_status = thisFormatter.attr('data-offer-status');
        var close_label = thisFormatter.attr('data-close-label');

        if ($.cookie('offer_info') != null && $.cookie('offer_info').length > 0) {
          $('.hidegnavoffer').show();
          show_link_based_offer();
        } else if (offer_status == 1 && !check_disabled_path()) {
          show_gnav_offer();
        }

        function show_link_based_offer() {
          var offer_code = $.cookie('offer_info').split(':')[1];

          generic.jsonrpc.fetch({
            method: 'offer.linkedOfferState',
            params: [offer_code],
            onSuccess: function (r) {
              thisFormatter.replaceWith(r.getValue().linked_offer.cms_content);
              thisFormatter = $('.link-based-offer-v1');
              var subHeading = thisFormatter.find('.subheading');

              subHeading.html(r.getValue().linked_offer.offer_message);

              // Validate Duplicate Headers
              var offerHeading = thisFormatter.find('h2 span');

              if (offerHeading.is(':empty')) {
                var offerMsgFromMgr = subHeading.find('h2');

                if (!offerMsgFromMgr.is(':empty') && offerMsgFromMgr != null) {
                  offerHeading.text(offerMsgFromMgr.text());
                }
              }
              thisFormatter.find('.outer-wrap').once().prepend('<div title="' + close_label + '" class="link-based-offer-close" />');
              thisFormatter.find('.link-based-offer-close').once().on('click', function() {
                thisFormatter.slideUp();
                // create session cookie
                $.cookie('hide_link_based_offer', '1', {
                  path: '/'
                });
              });
              // SET Call To Action Link Color and Font Size
              var cta_font_size = thisFormatter.find('.cta-link').attr('data-cta-link-font-size');
              var ctacolor = thisFormatter.find('.cta-link').attr('data-cta-link-color');

              thisFormatter.find('.cta-link a').css({
                'color': ctacolor,
                'font-size': cta_font_size + 'px'
              });

              // To Check SKUID or dynamic Image
              var fetchData = function (args) {
                generic.jsonrpc.fetch({
                  method: 'prodcat',
                  params: [args],
                  onSuccess: function (jsonRpcResponse) {
                    args.callback(jsonRpcResponse.getValue());
                  },
                  onError: function (jsonRpcResponse) {}
                });
              };
              var image_or_skuid = thisFormatter.attr('data-image-or-skuid');

              if (image_or_skuid > 0) {
                var sku_id = thisFormatter.find('.link-based-offer-image').attr('data-skubaseid');

                if (sku_id !== null && sku_id.length !== 0) {
                  var prodThumbImage = '';
                  var jsonRpcParams = {};

                  jsonRpcParams.skus = ['SKU' + sku_id];
                  jsonRpcParams.sku_fields = ['PRODUCT_ID'];
                  jsonRpcParams.callback = function (responseData) {
                    if (responseData !== null && responseData.length !== 0) {
                      if (responseData.skus !== null && responseData.skus.length !== 0) {
                        var prodjsonParams = {};

                        prodjsonParams.product_fields = ['THUMBNAIL_IMAGE'];
                        prodjsonParams.products = responseData.skus[0].PRODUCT_ID;
                        prodjsonParams.callback = function (productData) {
                          if (productData !== null && productData.length !== 0) {
                            if (productData.products !== null && productData.products.length !== 0) {
                              prodThumbImage = productData.products[0].THUMBNAIL_IMAGE;
                              thisFormatter.find('.link-based-offer-image').attr('src', prodThumbImage);
                            }
                          }
                        };
                      }
                      fetchData(prodjsonParams);
                    }
                  };
                }
                fetchData(jsonRpcParams);
              }
            },
            onError: function (r) {

            }
          });
        }

        function show_gnav_offer() {
          thisFormatter.find('.outer-wrap').once().prepend('<div title="' + close_label + '" class="gnav-offer-close" />');
          thisFormatter.find('.gnav-offer-close').once().on('click', function() {
            thisFormatter.slideUp();
            // create session cookie
            $.cookie('hide_gnav_offer', '1', {
              path: '/'
            });
          });
          // check session cookie
          var cookie = $.cookie('hide_gnav_offer');

          // if cookie does not exist
          if (cookie != '1') {
            // check offer status
            var offerCode = thisFormatter.children().attr('offer-code');

            // offer code is an optional field
            if (offerCode && offerCode.length > 0) {
              // check offer code timing status
              if ($('body').hasClass('env-cms') == false) {
                generic.jsonrpc.fetch({
                  method: 'offer',
                  params: [offerCode],
                  onSuccess: function (r) {
                    var offer = r.getValue();
                    var isActive = offer && (offer.timing === 'active');

                    if (isActive) {
                      // if active, show
                      thisFormatter.slideDown();
                    }
                  },
                  onFailure: function (r) {
                    // started pg server?
                  }
                });
              }
            } else {
              // if no cookie and no offer code, show
              thisFormatter.slideDown();
            } // end offer code check
          } // end cookie check
        }
        function check_disabled_path() {
          var disabled_path_selector = thisFormatter.find('.gnav-disabled-pages');

          if (disabled_path_selector.length > 0) {
            var disabled_paths = disabled_path_selector.text().split(',');

            for (var i = 0; i < disabled_paths.length; i++) {
              var disabled_path = disabled_paths[i];

              disabled_path = disabled_path.trim();
              disabled_path = disabled_path == '<front>' ? '/' : '/' + disabled_path;
              if (current_path == disabled_path) {
                return true;
              }
            }
          }

          return false;
        }
        if (thisFormatter.find('.gnav-expiry-date-time').length > 0) {
          var expiry_date_time = thisFormatter.find('.gnav-expiry-date-time').text(),
            server_date_time = server_date_today + ' ' + server_time_now,
            gnavCountdownContent = thisFormatter.find('.gnav-countdown-content'),
            gnavCountdown = gnavCountdownContent.find('.gnav-countdown');

          gnavCountdown.once('update-countdown').countdown(server_date_time, expiry_date_time)
            .on('update.countdown', function (event) {
              var format = '%H:%M:%S';
              var totalHours = event.offset.totalDays * 24 + event.offset.hours;

              /*
            if (event.offset.days > 0) {
              var day_text = event.offset.days === 1 ? rb.endeca.day_singular : rb.endeca.day_plural;
              format = '%-d' + day_text + ' ' + format;
            }
            if (event.offset.weeks > 0) {
              var week_text = event.offset.weeks === 1 ? rb.endeca.week_singular : rb.endeca.week_plural;
              format = '%-w' + week_text + ' ' + format;
            }
            $(this).html(event.strftime(format));
            */
              $(this).html(event.strftime(totalHours + ':%M:%S'));
              thisFormatter.find('.gnav-countdown-title').show();
            })
            .on('finish.countdown', function (event) {
              gnavCountdownContent.html('');
            });
        }
      });
    }
  };
})(jQuery);
